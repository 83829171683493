import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Account, AllAccounts } from '../../../shared/interfaces/accounts.interfaces';

export const AccountsActions = createActionGroup({
  source: 'Accounts',
  events: {
    'Load Accounts': props<{ payload: unknown }>(),
    'Load Accounts Success': props<{ data: AllAccounts }>(),
    'Load Accounts Failure': props<{ error: unknown }>(),

    'Load Account Details': props<{ id: number | string }>(),
    'Load Account Details Success': props<{ data: Account }>(),
    'Load Account Details Failure': props<{ error: unknown }>(),

    // TODO: tipar los data
    'Create Account': props<{ accountName: string, accountNumber: string }>(),
    'Create Account Success': props<{ data: any }>(),
    'Create Account Failure': props<{ error: unknown }>(),

    'Update Account': props<{ payload: { accountId: number; accountName: string; accountNumber: string } }>(),
    'Update Account Success': props<{ data: any }>(),
    'Update Account Failure': props<{ error: unknown }>(),

    'Link Equipment To Account': props<{ payload: { accountId: string | number, equipmentId: string | number } }>(),
    'Link Equipment To Account Success': props<{ data: any }>(),
    'Link Equipment To Account Failure': props<{ error: unknown }>(),

    'Remove Equipment From Account': props<{ sn: string | number }>(),
    'Remove Equipment From Account Success': props<{ data: any }>(),
    'Remove Equipment From Account Failure': props<{ error: unknown }>(),

    'Unset Accounts': emptyProps(),
  }
});
