import { createReducer, on } from '@ngrx/store';
import { AccountsActions } from './account.actions';
import { Account, AllAccounts } from '../../../shared/interfaces/accounts.interfaces';

export const accountsFeatureKey = 'accounts';

export interface AccountsState {
  isLoading: boolean;
  error: unknown;
  accountsData: AllAccounts | null;
  accountDetails: Account | null
}

export const initialState: AccountsState = {
  isLoading: false,
  error: null,
  accountsData: null,
  accountDetails: null
};

export const accountsReducer = createReducer(
  initialState,
  on(AccountsActions.loadAccounts, state => { return { ...state, isLoading: true } }),
  on(AccountsActions.loadAccountsSuccess, (state, action) => { return { ...state, isLoading: false, error: false, accountsData: action.data } }),
  on(AccountsActions.loadAccountsFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),

  on(AccountsActions.loadAccountDetails, state => { return { ...state, isLoading: true } }),
  on(AccountsActions.loadAccountDetailsSuccess, (state, action) => { return { ...state, isLoading: false, error: false, accountDetails: action.data } }),
  on(AccountsActions.loadAccountDetailsFailure, (state, action) => { return { ...state, isLoading: false, error: action.error } }),

  on(AccountsActions.unsetAccounts, () => initialState)
);
